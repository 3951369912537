<template>
    <div id="patrolReport">
        <sn-table-group
            :tableGroupAttributes="tableGroupAttributes"
            @handleChange="handleChange"
        ></sn-table-group>
        <div
            class="timePart flex-y-center"
            :class="system == 'system1' ? 'timePart1' : 'timePart2'"
        >
            <el-date-picker
                class="timeItem"
                v-model="startTime"
                size="mini"
                align="left"
                type="date"
                placeholder="起始时间"
                value-format="yyyy-MM-dd"
            ></el-date-picker>
            <div class="gap"></div>
            <el-date-picker
                v-model="endTime"
                size="mini"
                align="left"
                class="timeItem"
                type="date"
                placeholder="截止时间"
                value-format="yyyy-MM-dd"
            ></el-date-picker>
        </div>
        <div class="selectPart" v-if="system == 'system2'">
            <el-select
                v-model="typeId"
                placeholder="请选择"
                style="width: 140px; height: 70px"
                size="mini"
            >
                <el-option
                    v-for="(item, index) in typelist"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
import { commonData } from "./sn-table-group-common";
import { selfData } from "./sn-table-group-2008";
import commonMethodMixin from "./commonMethodMixin";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "",
    mixins: [commonMethodMixin],
    data() {
        return {
            tableGroupAttributes: {},
            commonData,
            selfData,
            keyword: "", // 关键字
            departmentValue: "",
            roleValue: "",
            bgnr: "", //报告内容
            bgfl: "", //报告分类
            startTime: "",
            endTime: "",
            typeId: 0,
            typelist: [
                {
                    id: 0,
                    name: "监测工作报告",
                },
                { id: 1, name: "巡查报告" },
            ],
        };
    },
    created() {},
    mounted() {
        this.getGwztList();
    },
    computed: {
        ...mapGetters(["system"]),
    },
    methods: {
        ...mapActions([
            "GetEnumItem",
            "CreatPatrolReport",
            "CreatSystemPatrolReport",
            "CreatLyjPatrolReport",
        ]),
        async getGwztList() {
            // 报告内容
            let res = await this.GetEnumItem({
                enumid: "c111c2ef-b15f-11eb-b3ce-000c2977b7fd",
            });
            this.tableGroupAttributes.snSearch.selectData[0].list = res;
            // 报告分类
            res = await this.GetEnumItem({
                enumid: "c111c408-b15f-11eb-b3ce-000c2977b7fd",
            });
            this.tableGroupAttributes.snSearch.selectData[1].list = res;
            // 是管理局的只有巡查报告，林业局有巡查报告和监测报告
            switch (this.system) {
                case "system1":
                    this.tableGroupAttributes.snSearch.selectData[0].list.map(
                        (item) => {
                            if (item.Name == "巡查报告") {
                                this.bgnr = item.Code;
                            }
                        }
                    );
                    this.tableGroupAttributes.snSearch.selectData[0].isShow = false;
                    this.tableGroupAttributes.snSearch.selectData[1].isShow = false;
                    break;
            }
            this.getTableData();
        },
        // 生成报告
        async newReport() {
            // 报告内容
            let url = "";
            switch (this.system) {
                case "system1":
                    url = "CreatSystemPatrolReport";
                    break;
                case "system2":
                    if (this.typeId == 0) {
                        // 林业局 监测报告
                        url = "CreatPatrolReport";
                    } else {
                        // 生成林业局巡查报告
                        url = "CreatLyjPatrolReport";
                    }
                    break;
            }
            let res = await this[url]({
                lx: "2",
                starttime: this.startTime,
                endtime: this.endTime,
            });
            if (res.RESULT) {
                this.common.showMsg("生成成功", "success");
            } else {
                this.common.showMsg("生成失败", "warning");
            }
            this.getTableData();
        },
        // 导出报告
        exportReport() {
            let obj = {
                url: window.REQUEST_URL + "Bggl/ExportBgglData",
                para: {
                    JwtToken: "",
                },
            };
            this.common.exportFile(obj);
        },
    },
};
</script>
<style lang="scss" scoped>
#patrolReport {
    width: 100%;
    height: 100%;
    position: relative;
    .selectPart {
        position: absolute;
        top: 76px;
        right: 263px;
    }
    .timePart {
        position: absolute;
        top: 76px;
        .timeItem {
            width: 140px;
        }
        .gap {
            margin: 0 10px;
            width: 10px;
            height: 2px;
            background: #dcdfe6;
        }
    }
    .timePart1 {
        right: 273px;
    }
    .timePart2 {
        right: 418px;
    }
}
</style>
