const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                    label: "报告名称",
                    prop: "BGMC"
                },
                {
                    label: "时间范围",
                    prop: "SJFW"
                },
                {
                    label: "报告内容",
                    prop: "BGNR"
                },
                {
                    label: "报告分类",
                    prop: "BGFL"
                },
                {
                    label: "附件",
                    prop: "BGLJ",
                    showFileBtn: true,
                },
                {
                    label: "报告生成时间",
                    prop: "CJSJ",
                },
            ],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '',
            clearable: true,
            placeholder: "请选择报告内容",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'bgnr',
            operateType: 'search',
            isShow: true
        }, {
            label: '',
            clearable: true,
            placeholder: "请选择报告分类",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'bgfl',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            showMethod: 'showNewBtn',
            name: '生成报告',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }

};
export { selfData };