import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            tableGroupAttributes: {},
            multipleSelection: [],
            tabheight: null,
            searchData: [],
            total: 10, // 每页显示几条数据
        };
    },
    mounted() {
        this.handleDataAll(this.selfData, this.commonData);
        this.tableGroupAttributes = this.selfData;
        let search = this.tableGroupAttributes.snSearch.selectData || [];
        if (search.length > 0) {
            search.forEach(item => {
                let type = item.value;
                this.searchData.push({ type: type, value: '' });
            });
        }

    },
    computed: {
        ...mapGetters(["system"]),
    },
    methods: {
        ...mapActions([
            "GetBgglList",
        ]),
        async getTableData() {
            let res = await this.GetBgglList({
                keyword: this.keyword,
                bgnr: this.bgnr ? this.bgnr : "",
                bgfl: this.bgfl ? this.bgfl : "",
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,
                depid: this.system == 'system2' ? localStorage.departmentId : ""
            });
            this.tableGroupAttributes.snTable.table.data =
                res.RESULTVALUE.RESULT;
            this.tableGroupAttributes.snPage.count = Number(
                res.RESULTVALUE.COUNT
            );
            this.tableGroupAttributes.loading = false;
        },
        handleDataAll(self, common) {
            Object.keys(common).forEach(key => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== 'object') return false;
                    if (key === 'snButton') return false;
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        handleChange(type, value) {
            console.log('%c 🥥 type, value: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', type, value);
            switch (type) {
                case 'search': // 搜索
                    this[value[1]] = value[0];
                    this.searchData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页的条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case 'searchBtn': // 点击查询按钮
                    this.getTableData();
                    break;
                case 'buttonDel': // 批量删除按钮
                    this.delSome();
                    break;
                case 'buttonNew': // 新建按钮
                    this.toAddPage();
                    break;
                case 'buttonExport': // 导出按钮
                    this.exportData();
                    break;
                case 'columnEdit': // 编辑按钮
                    this.toEditPage(value);
                    break;
                case 'columnDelete': // 删除按钮
                    this.deleteData(value[0].Id ? value[0].Id : value[0].ID); // TODO id
                    break;
                case 'selection': // 表格选择框
                    this.selectData(value[0]);
                    break;
                case 'fileBtn': // 下载附件
                    window.open(value[0].BGLJ)
                    break;
                default:
                    break;
            }
        },
        // 新建
        toAddPage() {
            if (this.startTime && this.endTime) {
                if (this.startTime > this.endTime) {
                    this.common.showMsg("起始时间不能大于截止时间", "warning")
                } else {
                    this.newReport()
                }
            } else {
                this.common.showMsg("请选择时间", "warning")
            }
        },
        // 修改
        toEditPage(value) {
            value[0].actType = 1
            this.$router.push({
                path: this.infoPage,
                query: value[0]
            });
        },
        exportData() {
            this.exportReport()
        },
        deleteData(value) {
            this.$confirm('是否永久删除该条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.delData(value);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info")
                });
        },
        delSome() {
            let checkBox = [];
            this.multipleSelection.forEach(function (o) {
                checkBox.push(o.ID ? o.ID : o.Id); // TODO id
            });
            if (checkBox.length === 0) {
                this.common.showMsg("请选择数据项！", "warning")
            } else {
                checkBox = checkBox.join();
                this.deleteData(checkBox);
            }
        },
        // 删除数据
        async delData(id) {
            let res = await this.DeleteSlhlyInfo({
                ids: id,
            });
            if (res) {
                this.common.showMsg("删除成功", "success")
                this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.common.showMsg("删除失败", "error")
            }
        },
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach(item => {
                    this.multipleSelection.push(item);
                });
            }
        }
    }
};