const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                type: 'selection',
                width: 35
            }, {
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }],
            // 数据列
            columnsData: [],
            // 操作列
            columnsBtn: {
                // buttonData: [{
                //     isShow: false,
                //     btnType: 'icon',
                //     operateType: 'columnEdit',
                //     title: '编辑',
                //     icon: 'el-icon-edit',
                //     color: 'green'
                // }, {
                //     isShow: false,
                //     btnType: 'icon',
                //     operateType: 'columnDelete',
                //     title: '删除',
                //     icon: 'el-icon-delete',
                //     color: 'red'
                // }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [{
            // label: '关键字',
            placeholder: "关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        searchBtn: {
            buttonData: [{
                isShow: false,
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            },]
        }

    },
};
export { commonData };